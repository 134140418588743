<template>
  <div class="list" style="max-width: 100%;">
    <b-alert v-model="error" variant="danger" dismissible>
      {{ errorMsg }}
    </b-alert>
    <b-alert v-model="confirmSuccess" variant="success" dismissible>
      {{ confirmMsg }}
    </b-alert>
    <b-alert v-model="emailSuccess" variant="success" dismissible>
      {{ emailMsg }}
    </b-alert>
    <b-card-group class="row justify-content-center" deck>
      <b-card
          style="max-width: 20rem; margin-right: 10px;"
          class="col-6"
          v-for="(boxType, index) in setTopBoxTypes"
          :key="index"
      >
        <b-card-title class="ubuntu">{{ boxType.name }}</b-card-title>
        <b-card-img v-if="boxType.name === 'Q20'" :src="require('../assets/Q20-2-768x480.png')" alt="Q20"
                    top></b-card-img>
        <b-card-img v-else-if="boxType.name === 'Ti9'" :src="require('../assets/Ti9.png')" alt="Ti9"
                    top></b-card-img>
        <b-card-img v-else-if="boxType.name === 'Q90'" :src="require('../assets/Q90.png')" alt="Q90"
                    top></b-card-img>
        <b-card-img v-else-if="boxType.name === 'Q80'" :src="require('../assets/q80.jpg')" alt="Q80"
                    top></b-card-img>
        <b-card-img v-else-if="boxType.name === 'Maximo'" :src="require('../assets/maximo.jpg')" alt="maximo"
                    top></b-card-img>
        <b-card-img v-else-if="boxType.name === 'Microsat'" :src="require('../assets/MICROSAT.png')" alt="microsat"
                    top></b-card-img>
        <b-card-text v-if="boxType.name === 'Q20'" class="sansSerif">
          {{ $t('home.q20') }}
        </b-card-text>
        <b-card-text v-else-if="boxType.name === 'Q30'" class="sansSerif">
          {{ $t('home.q30') }}
        </b-card-text>
        <b-card-text v-else-if="boxType.name === 'Ti9'" class="sansSerif">
          {{ $t('home.ti9') }}
        </b-card-text>
        <b-card-text v-else-if="boxType.name === 'Q90'" class="sansSerif">
          {{ $t('home.q90') }}
        </b-card-text>
        <b-card-text v-else-if="boxType.name === 'Q80'" class="sansSerif">
          {{ $t('home.q80') }}
        </b-card-text>
        <b-card-text v-else-if="boxType.name === 'Maximo'" class="sansSerif">
          {{ $t('home.maximo') }}
        </b-card-text>
        <b-card-text v-else-if="boxType.name === 'Microsat'" class="sansSerif">
          {{ $t('home.microsat') }}
        </b-card-text>
        <router-link v-if="boxType.name === 'Q20'" class="btn sansSerif"
                     style="background-color: #0000FF; border-radius: 0!important; color: white"
                     :to="{ name: 'Q20', query: { id: boxType.id, boxName: boxType.name} }">
          {{ $t('home.getPvrKey') }}
        </router-link>
        <router-link v-else-if="boxType.name === 'Q90'" class="btn sansSerif"
                     style="background-color: #0000FF; border-radius: 0!important; color: white"
                     :to="{ name: 'Q90', query: { id: boxType.id, boxName: boxType.name} }">
          {{ $t('home.getPvrKey') }}
        </router-link>
        <router-link v-else-if="boxType.name === 'Ti9'" class="btn sansSerif"
                     style="background-color: #0000FF; border-radius: 0!important; color: white"
                     :to="{ name: 'Ti9', query: { id: boxType.id, boxName: boxType.name} }">
          {{ $t('home.getPvrKey') }}
        </router-link>
        <router-link v-else-if="boxType.name === 'Maximo'" class="btn sansSerif"
                     style="background-color: #0000FF; border-radius: 0!important; color: white"
                     :to="{ name: 'Maximo', query: { id: boxType.id, boxName: boxType.name} }">
          {{ $t('home.getPvrKey') }}
        </router-link>
        <router-link v-else-if="boxType.name === 'Q80'" class="btn sansSerif"
                     style="background-color: #0000FF; border-radius: 0!important; color: white"
                     :to="{ name: 'Q80', query: { id: boxType.id, boxName: boxType.name} }">
          {{ $t('home.getPvrKey') }}
        </router-link>
        <router-link v-else-if="boxType.name === 'Microsat'" class="btn sansSerif"
                     style="background-color: #0000FF; border-radius: 0!important; color: white"
                     :to="{ name: 'Microsat', query: { id: boxType.id, boxName: boxType.name} }">
          {{ $t('home.getPvrKey') }}
        </router-link>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import SetTopBoxTypeDataService from "../services/SetTopBoxTypeDataService";
import UserDataService from "../services/UserDataService";

export default {
  name: "setTopBoxTypes-list",
  data() {
    return {
      setTopBoxTypes: [],
      currentSetTopBoxType: null,
      currentIndex: -1,
      name: "",
      error: false,
      errorMsg: '',
      confirmSuccess: false,
      confirmMsg: this.$i18n.messages[this.$i18n.locale].confirmSuccess,
      emailSuccess: false,
      emailMsg: '',
    };
  },
  methods: {
    retrieveSetTopBoxTypes() {
      SetTopBoxTypeDataService.getAll()
          .then(response => {
            this.setTopBoxTypes = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    refreshList() {
      this.retrieveSetTopBoxTypes();
      this.currentSetTopBoxType = null;
      this.currentIndex = -1;
    },

    setActiveSetTopBoxType(setTopBoxType, index) {
      this.currentSetTopBoxType = setTopBoxType;
      this.currentIndex = index;
    },

    removeAllSetTopBoxTypes() {
      SetTopBoxTypeDataService.deleteAll()
          .then(response => {
            console.log(response.data);
            this.refreshList();
          })
          .catch(e => {
            console.log(e);
          });
    },

    searchName() {
      SetTopBoxTypeDataService.findByName(this.name)
          .then(response => {
            this.setTopBoxTypes = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    confirmEmail(code) {
      UserDataService.confirmMail(code).then(response => {
        console.log(response);
        this.confirmSuccess = true;
      })
          .catch(e => {
            console.log(e);
            this.error = true;
            this.errorMsg = "There was an error confirming the account. Please try again later";
          });
    }
  },
  mounted() {
    this.retrieveSetTopBoxTypes();
    if (this.$route.params.confirmationCode) {
      console.log("PANOS IN DER HOS ");
      console.log(this.$route.params.confirmationCode)
      this.confirmEmail(this.$route.params.confirmationCode);
    }
    if (this.$route.params.msg) {
      console.log("PANOS IN DER HOS MAL 3");
      console.log(this.$route.params.msg);
      this.emailSuccess = true;
      this.emailMsg = this.$route.params.msg;
    }
    if (this.$route.params.deleteMsg) {
      console.log("PANOS IN DER HOS MAL 3");
      this.emailSuccess = true;
      this.emailMsg = this.$route.params.deleteMsg;
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');

.ubuntu {
  font-family: 'Ubuntu', sans-serif !important;
}

.sansSerif {
  font-family: 'Open Sans', sans-serif !important;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
